<template>
  <b-container>
    <Banner
      v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.media && $store.getters.selectedCampaign.media.length > 0"/>
    <div class="terms" v-if="windowToShow === 'terms'">
      <h3 class="terms__title">NOTĂ DE INFORMARE PENTRU PRELUCRAREA DATELOR CU CARACTER PERSONAL</h3>
      <div class="terms__content" v-if="gdprInfo" v-html="gdprInfo"></div>

      <div class="terms__buttons">
        <button class="btn login__btn terms__btn" @click="showAddParticipantModal()">Mai departe</button>
      </div>
    </div>
    <div class="user-page" v-if="windowToShow === 'form'">
      <h3 class="user__title text-center mb-4">Informatii personale</h3>
      <p class="user__min-bills"
         v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.min_amount && client.campaign_type!='amount_free' && client.campaign_type!='voucher' ">
        *Valoarea totala a bonurilor pentru aceasta campanie este de minim {{
          $store.getters.selectedCampaign.min_amount
        }} lei</p>
      <b-row class="user__row">
        <b-col sm="3">
          <label for="telephone">Numar telefon:<span v-if="!optionalPhone">*</span></label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="telephone"
            type="tel"
            name="phone"
            v-model="client.phone"
            class="login__input"
            v-validate="phoneValidation"
            data-vv-as="Telefon"
            :state="(errors.first('phone')) ? false : null"
            @blur="checkParticipant()"
          ></b-form-input>
          <span class="user__error">{{ errors.first('phone') }}</span>
        </b-col>
      </b-row>
      <b-row class="user__row">
        <b-col sm="3">
          <label for="firstName">Nume:*</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="firstName"
            type="text"
            name="first_name"
            v-model="client.first_name"
            class="login__input"
            v-validate="'required'"
            data-vv-as="Nume"
            :state="(errors.first('first_name')) ? false : null"
          ></b-form-input>
          <span class="user__error">{{ errors.first('first_name') }}</span>
        </b-col>
      </b-row>
      <b-row class="user__row">
        <b-col sm="3">
          <label for="lastName">Prenume:*</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="lastName"
            type="text"
            name="last_name"
            v-model="client.last_name"
            class="login__input"
            v-validate="'required'"
            data-vv-as="Prenume"
            :state="(errors.first('last_name')) ? false : null"
          ></b-form-input>
          <span class="user__error">{{ errors.first('last_name') }}</span>
        </b-col>
      </b-row>
      <b-row class="user__row">
        <b-col sm="3">
          <label for="email">Email: </label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            id="email"
            type="email"
            name="email"
            v-model="client.email"
            class="login__input"
            v-validate="{ required: false, email: true}"
            data-vv-as="Email"
            :state="(errors.first('email')) ? false : null"
          ></b-form-input>
          <span class="user__error">{{ errors.first('email') }}</span>
        </b-col>
      </b-row>
      <b-row class="user__row user__bill user__new-bill"
             v-if="client.campaign_type!='amount_free' && client.campaign_type!='voucher' "
             v-for="(receipt, index) in client.receipts" :key="index">
        <div class="user__sub-row">
          <b-col sm="3">
            <label :for="`shop${index}`">Magazin:*</label>
          </b-col>
          <b-col sm="9">
            <b-form-select
              :id="`shop${index}`"
              class="login__input campaign__input"
              v-model="receipt.shop"
              :options="shops"
              :name="`receipts.${index}.shop`"
              value-field="id"
              v-validate="'required'"
              text-field="name"
              data-vv-as="Magazin"
              :state="(errors.first(`receipts.${index}.shop`)) ? false : null"
            >
              <template slot="first">
                <option value="" disabled>-- Va rugam sa alegeti un magazin --</option>
              </template>
            </b-form-select>
            <span class="user__error">{{ errors.first(`receipts.${index}.shop`) }}</span>
          </b-col>
        </div>
        <div class="user__sub-row">
          <b-col sm="3">
            <label :for="`receiptNumber${index}`">Numar bon:*</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              :id="`receiptNumber${index}`"
              type="text"
              :name="`receipts.${index}.nr`"
              class="login__input"
              data-vv-as="Numar Bon"
              v-validate="'required|numeric'"
              :state="(errors.first(`receipts.${index}.nr`)) ? false : null"
              v-model="receipt.nr"
            ></b-form-input>
            <span class="user__error">{{ errors.first(`receipts.${index}.nr`) }}</span>
          </b-col>
        </div>
        <div class="user__sub-row">
          <b-col sm="3">
            <label :for="`receiptValue${index}`">Valoare bon:*</label>
          </b-col>
          <b-col sm="9">
            <b-form-input
              :id="`receiptValue${index}`"
              type="text"
              :name="`receipts.${index}.amount`"
              class="login__input"
              data-vv-as="Valoare Bon"
              v-validate="'required'"
              v-on:blur="calcTotalAmount()"
              :state="(errors.first(`receipts.${index}.amount`)) ? false : null"
              v-model="receipt.amount"
            ></b-form-input>
            <span class="user__error">{{ errors.first(`receipts.${index}.amount`) }}</span>
          </b-col>
        </div>
        <div class="user__add-bill" @click="addReceipts()" v-if="index === 0">
          <i class="fas fa-plus user__plus"></i>
          <p class="user__add-text">Adauga bon</p>
        </div>
        <div class="user__add-bill user__remove-bill" @click="removeReceipt(index)" v-if="index !== 0">
          <i class="fas fa-minus user__minus"></i>
        </div>
      </b-row>
      <div class="text-center" v-if="client.totalAmount>0">
        Suma totala : {{ client.totalAmount }}
      </div>
      <div class="text-center" v-if="client.receipts.length>1">
        Nr. total de bonuri : {{ client.receipts.length }}
      </div>
      <div v-if="client.campaign_type!='voucher' ">
        <b-row class="user__row">
          <b-col sm="3">
            <label for="prize">Premiu:*</label>
          </b-col>
          <b-col sm="9">
            <b-form-select
              id="prize"
              v-model="client.prize"
              :options="prizes"
              name="prize"
              value-field="id"
              text-field="name"
              class="login__input campaign__input"
              v-validate="'required'"
              data-vv-as="Premiu"
              :state="(errors.first('prize')) ? false : null"
            >
              <template slot="first">
                <option value="" disabled>-- Va rugam sa alegeti un premiu --</option>
              </template>
            </b-form-select>
            <span class="user__error">{{ errors.first('prize') }}</span>
          </b-col>
        </b-row>
        <b-row class="user__row">
          <b-col sm="3">
            <label for="talon">Talon:</label>
          </b-col>
          <b-col sm="9">
            <b-form-select
              id="talon"
              v-model="client.talon"
              name="talon"
              v-validate="'required'"
              class="login__input campaign__input"
              data-vv-as="Talon"
            >
              <template slot="first">
                <option value="" disabled>-- Participantul a primit talon? --</option>
              </template>
              <option value="1">DA</option>
              <option value="0">NU</option>
            </b-form-select>
            <span class="user__error">{{ errors.first('talon') }}</span>
          </b-col>
        </b-row>
      </div>
      <div v-if="client.campaign_type=='voucher' ">
        <b-row class="user__row user__bill user__new-bill"
               v-for="(voucher, index) in client.vouchers" :key="index">
          <div class="user__sub-row">
            <b-col sm="3">
              <label :for="`voucher${index}`">Voucher:*</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                :id="`voucher${index}`"
                class="login__input campaign__input"
                v-model="voucher.type"
                :options="voucher_array"
                :name="`vouchers.${index}.type`"
                value-field="id"
                text-field="name"
                data-vv-as="Vouchers"
                :state="(errors.first(`receipts.${index}.type`)) ? false : null"
              >
                <template slot="first">
                  <option value="" disabled>-- Va rugam sa alegeti un voucher --</option>
                </template>
              </b-form-select>
              <span class="user__error">{{ errors.first(`receipts.${index}.type`) }}</span>
            </b-col>
          </div>
          <div class="user__sub-row">
            <b-col sm="3">
              <label :for="`voucher_number${index}`">Cantitate:*</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                :id="`voucher_number${index}`"
                type="text"
                :name="`voucher_number.${index}.cantitate`"
                class="login__input"
                data-vv-as="cantitate"
                :state="(errors.first(`vouchers.${index}.cantitate`)) ? false : null"
                v-model="voucher.cantitate"
              ></b-form-input>
              <span class="user__error">{{ errors.first(`vouchers.${index}.cantitate`) }}</span>
            </b-col>
          </div>
          <div class="user__add-bill" @click="addVoucher()" v-if="index === 0">
            <i class="fas fa-plus user__plus"></i>
            <p class="user__add-text">Adauga Voucher</p>
          </div>
          <div class="user__add-bill user__remove-bill" @click="removeVoucher(index)" v-if="index !== 0">
            <i class="fas fa-minus user__minus"></i>
          </div>
        </b-row>
      </div>
      <div class="user-signature">
        <p class="user-signature__text">Semnatura</p>
        <VueSignaturePad
          width="100%"
          height="200px"
          ref="prizeSignature"
          saveType="image/png"
          class="terms__signature"
        />
      </div>
      <div class="terms__buttons confirm__buttons">
        <button type="submit" class="btn login__btn user__btn" @click="validateParticipant()">Salveaza</button>
        <button type="submit" class="btn btn-secondary terms__btn terms__btn--secondary remove__btn"
                @click="removeSignatureForm()">Sterge semnatura
        </button>
      </div>
      <b-modal id="modal-confirm" hide-footer>
        <template slot="modal-title">
          Confirmare participant
        </template>
        <div class="d-block">
          <p class="modal__text">Informatii personale participant</p>
          <p class="modal__field">Numar telefon: <span class="modal__data">{{ client.phone }}</span></p>
          <p class="modal__field">Nume: <span class="modal__data">{{ client.first_name }}</span></p>
          <p class="modal__field">Prenume: <span class="modal__data">{{ client.last_name }}</span></p>
          <p class="modal__field">Email: <span class="modal__data">{{ client.email }}</span></p>
          <div v-if="client.campaign_type!='voucher' ">
            <div class="modal__bill" v-for="(bill, index) in client.receipts" :key="index">
              <p class="modal__field">Magazin: <span class="modal__data">{{
                  (shops.find(shop => shop.id === bill.shop)) ? shops.find(shop => shop.id === bill.shop).name : ''
                }}</span></p>
              <p class="modal__field">Numar bon: <span class="modal__data">{{ bill.nr }}</span></p>
              <p class="modal__field">Valoare bon: <span class="modal__data">{{ bill.amount }}</span></p>
            </div>
            <p class="modal__field">Premiu: <span class="modal__data">{{
                (prizes.find(prize => prize.id === client.prize)) ? prizes.find(prize => prize.id === client.prize).name : ''
              }}</span></p>
            <p class="modal__field">Talon: <span class="modal__data">{{
                (parseInt(client.talon) === 1) ? 'DA' : 'NU'
              }}</span></p>
          </div>
          <div v-if="client.campaign_type=='voucher' ">

            <div class="modal__bill" v-for="(voucher,index) in client.vouchers" :key="index">
              <p class="modal__field">Voucher: <span class="modal__data">{{ voucher.type }}</span></p>
              <p class="modal__field">Cantitate: <span class="modal__data">{{ voucher.cantitate }}</span></p>
            </div>
          </div>

        </div>
        <b-button class="mt-3 modal__btn modal__btn--save" block @click="addParticipant()">Salveaza</b-button>
        <b-button class="mt-3 modal__btn" block @click="$bvModal.hide('modal-confirm')">Anulare</b-button>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import Banner from '../../components/Banner'
import ClientService from '../../api-services/Client'
import CampaignService from '../../api-services/Campaign'
import GlobalService from '../../api-services/Global'

export default {
  name: 'AddParticipant',
  data () {
    return {
      optionalPhone: this.$store.getters.selectedCampaign.phone_optional,
      client: {
        totalAmount: 0,
        phone: '',
        first_name: '',
        last_name: '',
        email: '',
        campaign: this.$store.getters.selectedCampaign.id,
        campaign_type: this.$store.getters.selectedCampaign.type,
        prize: '',
        signatureGdpr: null,
        signaturePrize: null,
        receipts: [
          {
            shop: null,
            nr: null,
            amount: null
          }
        ],
        vouchers: [
          {
            type: null,
            cantitate: 1
          }
        ],
        talon: '',
        voucher: 0,

        number_of_voucher: 0
      },
      voucher_array: [
        'Card cadou 50 RON',
        'Card cadou 100 RON'
      ],

      prizes: [],
      shops: [],
      windowToShow: 'terms',
      gdprInfo: null,
      gdprInfo2: null,
      gdprStatus: null

    }
  },
  components: {
    Banner
  },

  methods: {
    validateParticipant () {
      this.$validator.validateAll().then(success => {
        if (success && this.confirmPrize()) {
          this.$bvModal.show('modal-confirm')
        }
      })
    },
    dataURLtoFile (dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    getPrizes () {
      return new Promise((resolve, reject) => {
        this.prizes = []
        CampaignService.getPrizesForCampaign(this.$store.getters.selectedCampaign.id)
          .then((response) => {
            if (response.data.data.length > 0) {
              this.prizes = response.data.data
              let vm = this
              this.prizes.forEach(function (prize, index) {
                if (prize.stock > 0) {
                  vm.prizes[index]['disabled'] = false
                  vm.prizes[index]['name'] += ` (Stoc: ${prize.stock} buc.)`
                } else {
                  vm.prizes[index]['disabled'] = true
                  vm.prizes[index]['name'] += ` (Stoc: ${prize.stock} buc.)`
                }
              })
            }
            resolve(response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getShops () {
      return new Promise((resolve, reject) => {
        this.shops = []
        CampaignService.getShopsForCampaign(this.$store.getters.selectedCampaign.id)
          .then((response) => {
            if (response.data.data.length > 0) {
              this.shops = response.data.data
            }
            resolve(response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getGDPR () {
      this.gdprInfo = this.$store.getters.selectedCampaign.gdpr.gdpr_info
      this.gdprInfo2 = this.$store.getters.selectedCampaign.informativeNotes.gdpr_info
    },
    addParticipant () {
      this.$bvModal.hide('modal-confirm')
      this.$store.dispatch('showLoading')
      this.confirmPrize()
      if (!this.client.signaturePrize) return false
      let formData = new FormData()
      formData.append('phone', this.client.phone)
      formData.append('first_name', this.client.first_name)
      formData.append('last_name', this.client.last_name)
      formData.append('email', this.client.email)
      formData.append('campaign', this.client.campaign)
      formData.append('prize', this.client.prize)
      formData.append('signatureGdpr', this.client.signatureGdpr)
      formData.append('signaturePrize', this.client.signaturePrize)
      formData.append('receiptsForOffline', JSON.stringify(this.client.receipts))
      formData.append('talon', this.client.talon)
      formData.append('voucher', JSON.stringify(this.client.vouchers))
      this.client.receipts.forEach(function (element, key) {
        formData.append(`receipts[${key}][nr]`, element.nr)
        let temp = element.amount
        if (temp) {
          temp = temp.replace(/,/g, '.')
        }
        formData.append(`receipts[${key}][amount]`, temp)
        formData.append(`receipts[${key}][shop]`, element.shop)
      })
      // console.log(formData);
      let vm = this
      ClientService.addParticipant(formData)
        .then((response) => {
          let campaign = vm.$store.getters.selectedCampaign
          let prizeIndex = campaign.campaignStocks.findIndex(prize => prize.prize_id === vm.client.prize)
          if (campaign.campaignStocks[prizeIndex]) {
            campaign.campaignStocks[prizeIndex]['stock']--
          }
          let participant = {
            campaign_id: vm.$store.getters.selectedCampaign.id,
            client_id: null,
            client_phone: vm.client.phone ? vm.client.phone : null,
            employee_id: vm.$store.getters.user.id,
            id: null,
            prize_id: vm.client.prize ? vm.client.prize : null,
            date: null
          }
          campaign.campaignParticipants.push(participant)
          vm.$store.dispatch('syncSelectedCampaign', campaign).then((resp) => {
            this.$store.dispatch('hideLoading')
            this.$swal({
              title: 'Succes',
              html: 'Participantul a fost adaugat cu succes',
              type: 'success'
            }).then((result) => {
              vm.$router.push({ name: 'landing-page' })
            })
          })
        })
        .catch((error) => {
          this.$store.dispatch('hideLoading')
          if (error.response && typeof error.response.data.error === 'object') {
            const entries = Object.entries(error.response.data.error)
            let firstKey = null
            for (const [key, err] of entries) {
              if (!firstKey) {
                firstKey = key
              }
              const field = this.$validator.fields.find({ name: key, scope: this.$options.scope })
              if (!field) continue
              this.$validator.errors.add({
                id: field.id,
                field: key,
                msg: err[0],
                scope: this.$options.scope
              })
            }

            let input = document.getElementsByName(firstKey)
            window.scrollTo(0, input.offsetTop - 50)
          } else if (error.error) {
            this.$swal({
              title: 'Atentie',
              html: error.response.data.error,
              type: 'warning'
            })
          } else {
            if (error.response.data.message === 'A non-numeric value encountered') {
              this.$swal({
                title: 'Atentie',
                html: 'Eroare! Campul valoare bon nu contine valori numerice',
                type: 'warning'
              })
            } else {
              this.$swal({
                title: 'Atentie',
                html: error.response.data.error,
                type: 'warning'
              })
            }
          }
        })
    },
    addReceipts () {
      this.client.receipts.push({ nr: null, amount: null, shop: null })
      this.client.nrOfReceipts = this.client.receipts.length
    },
    addVoucher () {
      console.log(this.client.vouchers)
      this.client.vouchers.push({ type: null, cantitate: 2 })
      // this.client.vouchers.length = this.client.vouchers.length;
    },
    removeVoucher (index) {
      this.client.vouchers.splice(index, 1)
    },
    calcTotalAmount () {
      let sum = 0
      this.client.receipts.forEach(function (element) {
        element.amount = parseFloat(element.amount).toFixed(2)
      })
      sum = this.client.receipts.reduce(function (a, b) {
        return { amount: +parseFloat(a.amount).toFixed(2) + +parseFloat(b.amount).toFixed(2) }
      })
      this.client.totalAmount = parseFloat(sum.amount).toFixed(2)
    },
    removeReceipt (index) {
      this.client.receipts.splice(index, 1)
    },
    checkParticipant () {
      if (!this.client.phone) {
        return false
      }
      ClientService.checkParticipant(this.client.phone, this.$store.getters.selectedCampaign.id)
        .then((response) => {
          let client = response.data.data
          if (client) {
            if (this.$store.getters.selectedCampaign.nr_of_participations !== 0 &&
              this.$store.getters.selectedCampaign.nr_of_participations_per_day !== 0 &&
              client.participation_nr <= this.$store.getters.selectedCampaign.nr_of_participations
            ) {
              this.$swal({
                title: 'Client existent',
                text: 'Acest client exista deja in evidenta noastra!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Da, autocompleteaza datele!',
                cancelButtonText: 'Nu!'
              }).then((result) => {
                if (result.value) {
                  this.resetClient()
                  this.client.first_name = (client.first_name) ? client.first_name : ''
                  this.client.last_name = (client.last_name) ? client.last_name : ''
                  this.client.email = (client.email) ? client.email : ''
                }
              })
            } else {
              if (this.$store.getters.selectedCampaign.nr_of_participations == 0 || client.participation_nr >= this.$store.getters.selectedCampaign.nr_of_participations) {
                this.$swal({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Din pacate acesta persoana a atins numarul maxim de participari la aceasta campanie!'
                }).then((result) => {
                  this.$router.push({ name: 'landing-page' })
                })
              } else if (this.$store.getters.selectedCampaign.nr_of_participations_per_day == 0 || client.participation_nr_today >= this.$store.getters.selectedCampaign.nr_of_participations_per_day) {
                this.$swal({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Din pacate acesta persoana a atins numarul maxim de participari pe zi la aceasta campanie!'
                }).then((result) => {
                  this.$router.push({ name: 'landing-page' })
                })
              }
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.error === 'error_max_participations_per_day') {
            this.$swal({
              type: 'error',
              title: 'Oops...',
              text: 'Din pacate acesta persoana a atins numarul maxim de participari pe zi la aceasta campanie!'
            }).then((result) => {
              this.$router.push({ name: 'landing-page' })
            })
          } else if (error.response && error.response.data.error === 'error_max_participations') {
            this.$swal({
              type: 'error',
              title: 'Oops...',
              text: 'Din pacate acesta persoana a atins numarul maxim de participari aceasta campanie!'
            }).then((result) => {
              this.$router.push({ name: 'landing-page' })
            })
          }
        })
    },
    resetClient () {
      this.client.first_name = ''
      this.client.last_name = ''
      this.client.email = ''
    },
    saveSignature () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (this.gdprStatus === null) {
        this.throwWarning('Va rugam sa bifati una din cele 2 variante')
        return false
      }
      if (this.gdprStatus == 1) {
        if (isEmpty) {
          this.throwWarning('Va rugam sa semnati acordul privind prelucrarea datelor cu caracter personal')
          return false
        }

        this.client.signatureGdpr = this.dataURLtoFile(data, 'signature.png')
      }
      this.windowToShow = 'form'
    },
    showAddParticipantModal() {
        this.windowToShow = 'form';
    },
    removeSignature () {
      this.$refs.signaturePad.undoSignature()
    },
    removeSignatureForm () {
      this.$refs.prizeSignature.undoSignature()
    },
    confirmPrize () {
      const { isEmpty, data } = this.$refs.prizeSignature.saveSignature()
      if (isEmpty) {
        this.throwWarning('Nu ai completat semnatura!')
        this.$store.dispatch('hideLoading')
        return false
      } else {
        this.client.signaturePrize = this.dataURLtoFile(data, 'signaturePrize.png')
        return true
      }
    },
    throwWarning (message) {
      this.$swal({
        title: 'Atentie',
        html: message,
        type: 'warning'
      })
    },
    dismiss () {
      this.$router.push({ path: '/' })
    },
    range (start, end) {
      let years = []
      while (start <= end) {
        years.push(start)
        start++
      }
      return years
    },
    createMonths () {
      let months = [{ nr: '01', month: 'Ianuarie' }, { nr: '02', month: 'Februarie' }, {
        nr: '03',
        month: 'Martie'
      }, { nr: '04', month: 'Aprilie' },
      { nr: '05', month: 'Mai' }, { nr: '06', month: 'Iunie' }, { nr: '07', month: 'Iulie' }, {
        nr: '08',
        month: 'August'
      },
      { nr: '09', month: 'Septembrie' }, { nr: '10', month: 'Octombrie' }, { nr: '11', month: 'Noiembrie' }, {
        nr: '12',
        month: 'Decembrie'
      }]
      return months
    },
    scrollToTop () {
      const c = document.documentElement.scrollTop || document.body.scrollTop
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, c - c / 7)
      }
    }
  },
  async created () {
    if (navigator.onLine) {
      await this.$store.dispatch('changeCampaign', this.$store.getters.selectedCampaign.id)
    }
    await this.getPrizes()
    await this.getShops()
    await this.getGDPR()
    this.$store.dispatch('hideLoading')
  },
  computed: {
    phoneValidation () {
      return this.optionalPhone ? 'numeric|min:10|max:15' : 'required|numeric|min:10|max:15'
    }
  }
}
</script>
